.lobbyMenuHide {
    height: 0;
    transition: height 0.15s ease-out;
    overflow: hidden;
    /* background: #d5d5d5; */
}
.lobbyMenuShow1, .lobbyMenuShow2, .lobbyMenuShow3 {
    height: 100%;
    transition: height 0.25s ease-in;
}

.RegisterSection{
    display : flex;
    width : 100%;
    min-width: 700px;
    margin-bottom: 1%;
}
.RegisterTitle{
    width: 200px;
}
.RegisterSection>input{
    width: 400px;
    border : 1px solid lightgray;
    height: 32px;
}
.addFileWrapper, .editFileWrapper{
    display: flex;
    justify-content : center;
    align-items: center;
    position: relative;
}
.addFileNameContainer, .editFileNameContainer{
    border : 1px solid lightgray;
    height: 32px;
    width: 400px;
}
.editFileNameContainer{
    max-width: 400px;
}
.editFileNameContainer, .editFileInputContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
/* .editFileInputContainer{
    align-items: flex-start;
} */
.editFileInputContainer>div{
    border-color: transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.addFileWrapper>input, .editFileWrapper>.editFileInputContainer>input{
    position: absolute;
    top: 0;
    border:1px solid;
    visibility: hidden;
}
.inputWrapper{
    display: flex;
}
.inputWrapper>input{
    min-width: 200px;
    width:100%;
}