.inappModal{
    display: flex;
    justify-content: center;
}
.inappModal .ant-modal-content{
    border-radius: 10px 10px 0 0;
    width: 314px;
    min-height: 120px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #FFFFFF;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 45px;
    margin-left : auto;
    margin-right : auto;
}
.inappModal .ant-modal-body {
    padding: 0!important;
    font-family: Noto Sans KR;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
}
.inappModal .ant-modal-body .ant-modal-confirm-content{
    text-align: center;
    padding: 0px 20px;
}
.inappModal .ant-modal-confirm-btns{
    position: absolute;
    bottom: -45px;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
}
.inappModal .ant-btn{
    width : 100%;
    height: 45px;
}
.inappModal .ant-btn-primary{
    border-radius: 0 0 10px 10px;
    background-color: #00A9FF !important;
    border-color: #00A9FF !important;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}
.inappModalConfirm .ant-btn-primary{
    width : 50%;
    border-radius: 0 0 0 10px;
    margin: 0 !important;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}
.inappModalConfirm .ant-btn-default, .inappModalConfirm .ant-btn-default:hover{
    width : 50%;
    border-radius: 0 0 10px 0;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
    background-color: #8F8F8F;
    border-color: #8F8F8F;
    color: white;
}
.inappModal .ant-btn span{
    font-family: Noto Sans;
    font-size: 15px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: -0.5px;
    text-align: center;
}
