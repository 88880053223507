.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* ==================== antd override ==================== */
.ant-menu {
  background-color: #f0f0f0;
}

.ant-menu-title-content {
  overflow: initial !important;
}

.ant-table-tbody>tr>td {
  padding: 12px !important;
}
/* 파일 업로드 자세히 보기 관련 */
.tableLayout tr td.ant-table-selection-column, .tableLayout tr th.ant-table-selection-column{
	/* display: none !important; */
  padding: 0 !important;
  }
.tableLayout tr td, .tableLayout tr th{
  padding: 4px 15px !important;
}
.tableLayout .ant-table-column-title{
  color: #292929;
  text-align: right;
  font-size: 16px;
  font-weight: 500;
}
.tableLayout tr th:nth-child(2) .ant-table-column-title{
  text-align: left;
}
.tableLayout .tableChecked>td,
.tableLayout .tableChecked:hover>td{
  overflow: hidden;
  background: #E5F6FF !important;
  
}
.tableLayout .tableChecked>td:nth-child(2), .tableLayout .tableChecked:hover>td:nth-child(2){
  border-left: 1px solid #00A9FF;
  border-top: 1px solid #00A9FF;
  border-bottom: 1px solid #00A9FF;
  box-sizing: border-box;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
}
.tableLayout tr:hover>td, .tableLayout tr:hover{
  background-color: #fff !important;
}
.tableLayout tr:hover>td:nth-child(3),
.tableLayout tr:hover>td:nth-child(4),
.tableLayout .tableChecked>td:nth-child(3),
.tableLayout .tableChecked>td:nth-child(4){
  border-top: 1px solid #00A9FF;
  border-bottom: 1px solid #00A9FF;
}
.tableLayout tr:hover>td:nth-child(2){
  border-left: 1px solid #00A9FF;
  border-top: 1px solid #00A9FF;
  border-bottom: 1px solid #00A9FF;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.tableLayout tr:hover>td:last-child{
  border-right: 1px solid #00A9FF;
  border-top: 1px solid #00A9FF;
  border-bottom: 1px solid #00A9FF;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.tableLayout .tableChecked>td:last-child, .tableLayout .tableChecked:hover>td:last-child{
  border-right: 1px solid #00A9FF;
  border-top: 1px solid #00A9FF;
  border-bottom: 1px solid #00A9FF;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
/* .ant-menu-hidden {
  display: inline !important;
} 

.ant-menu-inline-collapsed .ant-menu-submenu-arrow:after, .ant-menu-submenu-inline .ant-menu-submenu-arrow:after {
  transform: rotate(45deg) translateX(-2.5px) !important;
}
.ant-menu-inline-collapsed .ant-menu-submenu-arrow:before, .ant-menu-submenu-inline .ant-menu-submenu-arrow:before {
  transform: rotate(-45deg) translateX(2.5px) !important;
}

.ant-spin-nested-loading>div>.ant-spin {
  max-height: none !important;
}
*/
.ant-picker-range .ant-picker-clear {
  display: none !important;
}
.ant-picker-input>input:disabled{
  color:rgba(0,0,0,.85) !important;
}
.ant-modal-confirm-body>.anticon{
  margin-bottom: 1em;
}