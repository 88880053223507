body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: auto;
    overflow-x: hidden;
}
::-webkit-scrollbar {
    display: none;
}
/* Track */
/* ::-webkit-scrollbar-track {
background: #dfe3ee;
} */
/* Handle */
::-webkit-scrollbar-thumb {
    background: #b6b6b6;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #b6b6b6;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
    font-family: 'Noto Sans KR';
    font-weight: 300;
    src: url('/public/fonts/NotoSansKR-Light.otf') format('woff2'),
        url('/public/fonts/NotoSansKR-Light.otf') format('woff'),
        url('/public/fonts/NotoSansKR-Light.otf') format('truetype');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-weight: 400;
    src: url('/public/fonts/NotoSansKR-Regular.otf') format('woff2'),
        url('/public/fonts/NotoSansKR-Regular.otf') format('woff'),
        url('/public/fonts/NotoSansKR-Regular.otf') format('truetype');
}
@font-face {
    font-family: 'Noto Sans KR';
    font-weight: 500;
    src: url('/public/fonts/NotoSansKR-Medium.otf') format('woff2');
}

@font-face {
    font-family: 'Noto Sans KR';
    font-weight: 700;
    src: url('/public/fonts/NotoSansKR-Bold.otf') format('woff2'),
        url('/public/fonts/NotoSansKR-Bold.otf') format('woff'),
        url('/public/fonts/NotoSansKR-Bold.otf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 100;
    src: url('/public/fonts/Montserrat-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 300;
    src: url('/public/fonts/Montserrat-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 400;
    src: url('/public/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 500;
    src: url('/public/fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 700;
    src: url('/public/fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 800;
    src: url('/public/fonts/Montserrat-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Montserrat';
    font-weight: 900;
    src: url('/public/fonts/Montserrat-Black.ttf') format('truetype');
}
* {
    box-sizing: border-box;
    font-family: 'Noto Sans KR', sans-serif;
}
