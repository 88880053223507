.sideUiLayout{
    margin: 0px;
    width: 256px;
    height: 45px;
    padding-left: 25px;
    list-style: none;
    line-height: 40px;
    list-style-position: inside;
    list-style-type: disc;
    box-sizing: border-box;
    font-variant: tabular-nums;
    text-align: left;
}
.sideLiLayout{
    margin: 0px;
    width: 256px;
    height: 45px;
    list-style: none;
    line-height: 40px;
    list-style-position: inside;
    padding-left: 50px;
    overflow: hidden;
    white-space: nowrap;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    background-color: #fafafa;
    outline: none;
    cursor: pointer;
}
.sideLiLayout div{
    color: rgba(0,0,0,0.85);
}
.sideLiLayout div:hover, .sideLiLayout.selected div {
    color: #1890ff;
}