.EnFont{
    font-family: 'Montserrat' !important;
}
.flexRow{
    display: flex;
}
.flexCol{
    display: flex;
    flex-direction: column;
}
.cursorPointer{
    cursor: pointer;
}
.ant-back-top{
    left: 93vw !important;
}
.ant-back-top-content{
    background-color: transparent;
    width: 7vw;
    height: 7vw;
    background-image: url('./images/01_btn_top.png');
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
}
.topBtnWrapper .ant-modal {
    top: 38% !important;
}
.pageWrapper{
    background-color: #000000;
    overflow-x: hidden;
    width: 100vw;
}
.pageWrapper>div{ 
    width: 100%;
    text-align: center;
    color:white;
}
.topNav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #000000;
    height: 100px;
    color: white;
    padding: 1.7rem;
    font-size: 0.625vw;
    width: 100vw;
}
.topNav>.logo{
    max-height: 100%;
    width: auto;
    cursor: pointer;    
}
.topNav>.topMenuList>div{
    padding: 0 3vw;
    font-size: 1.1rem;
}
.langu{
    margin-right: 1vw;
    font-size: 0.8rem;
}
.fullImage{
    justify-content: center;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 100% auto;
    color: white;
    position: relative;
    
}
.topImage{
    background-image: url('./images/01_main_image.png');
    justify-content: center;
    align-items: flex-start;
}
.topImage>div>.logo2{
    height: auto;
}
.hiddenImage{
    position: relative;
    top:0;
    left:0;
    visibility: hidden;
    width: 100%;
    height: auto;
}
.topTitle{
    position: absolute;
    top:15vw;
    left:12.5vw;
    align-items: flex-start;
    text-align: left;
}
.topTitleMain{
    font-size: 1.5625vw;
    margin-bottom: 4vw;
}
.tempFull{
    color: white;
}
.tempFull>.logo2{
    visibility: hidden;
}

.tempFull>.topTitleMain{
    margin-bottom: 5.5vw;
    visibility: hidden;  
}
.topDownlod{
    font-size: 1.2vw;
    margin-bottom: 0.83vw;
}
.tempFull>.topDownlod{
    font-weight: 500;
}
.topBtnIcon{
    width: 10vw;
    height: auto;
}
.topBtnIcon:first-child{
    margin-right: 0.78vw;
}
.logo2{
    width: 17.6vw;
    height: auto;
    margin-bottom: 1.56vw;
}
.IntroText{
    align-items: center;
}
.IntroTextImage{
    margin-top: 11.04vw;
    margin-bottom: 4vw;
    width: 32.26vw;
    height: auto;
}
.centerText{
    height: 14.03vw;
    margin-bottom: 5.21vw;
    font-size: 1.56vw;
    font-weight: 400;
    line-height: 3vw;
    text-align: center;
    justify-content: flex-start;
}
.centerText2{
    height: 4.68vw;
}
.centerText3{
    height: 2.34vw;
}
.fullVideo{
    /* background-color: white; */
    width: 83.33vw;
    border: 1px solid white;
    height: auto;
}
.fullVideo>img{
    /* 임시이미지 */
    width: 100%;
    height: auto;
}
.bodyContents{
    align-items: center;
    background-image: url('./images/03_function_bg.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto 100%;
}
.bodyContentContainer{
    width: 78.96vw;
    height: 61.927vw;
    margin: 5.21vw auto 7.91vw;
    background-image: url('./images/03_M_line2.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% auto;
    display: grid;
    grid-template-rows: repeat(auto-fill, minmax(50%, auto));;
    grid-template-columns: repeat(auto-fill, minmax(50%, auto));;
    gap:0;
}
.bodyContentItem{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    font-weight: 400;
}
.bodyContentItem2{
    align-items: flex-end;
    text-align: right;
}
.bodyContentItem3>img{
    margin-top: 6.5vw;
}
.bodyContentItem>img{
    width: 4.06vw;
    height: auto;
    margin-bottom: 1.04vw;
}
.bcMainText{
    font-size: 1.82vw;
    margin-bottom: 2vw;
}
.bcBlueText{
    font-size: 1.3vw;
    margin-bottom: 0.5vw;
    color: #00A9FF;
}
.bcText{
    font-size: 1.3vw;
    margin-bottom: 0.52vw;
    width: 23.5vw;
    word-break: keep-all;
}
.bodySlideList{
    /* height: 86vw; */
    display: grid;
    grid-template-rows: repeat(auto-fill, minmax(33%, auto));
    gap:0;
}
.bodySlideRow{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50%, auto));
    gap:0;
}
.bodySlideItem{
    width: 50vw;
    /* border: 1px solid white */
}
.textItem2{
    padding: 4.6vw 8.5vw;
    text-align: left;
    word-break: keep-all;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.slideMainText{
    font-size: 1.8vw;
    margin-bottom: 1.25vw;
}
.slideBlueText{
    font-size: 1.3vw;
    color: #00A9FF;
    margin-bottom: 0.5vw;
}
.slideText{
    font-size: 1.2vw;
    font-weight: 300;
}

.bodySlideBg{
    background-image: url('./images/03_function_bg2.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.bodyImg{
    height: 29vw;
    width: 49.5vw;
    overflow: hidden;
    z-index: 1;
}
.bodyImg>img{
    height: 100%;
    width: auto;
}



.centerTitleImg {
    justify-content: center;
    align-items: center;
}
.centerTitleImg>img{
    width: 28%;
    margin-right: 1vw;
}
.centerTitle{
    font-size: 2.7vw;
    font-weight: 700;
    margin-top: 10.41vw;
    margin-bottom: 1.56vw;
    font-family: 'Montserrat' !important;
}
.communiContainer{
    background: #002233;
}
.communiTitleGroup>.centerText{
    margin-bottom: 5.208vw;
}
.communiBody{
    padding : 6.51vw 2.5vw;
    justify-content: space-around;
    /* margin-bottom: 27.08vw; */
}
.communicard{
    background: #FFFFFF;
    border-radius: 10px;
    width: 30.8vw;
    height: 32.3vw;
    padding: 1.04vw;
    text-align: center;
    color: #000000;
    margin-bottom: 10.42vw;

}
.communicard>img{
    border-radius: 0.52vw;
}
.communiTitle{
    font-size: 2.08vw;
    font-weight: 400;
    line-height: 3.125vw;
    margin: 2.08vw auto 1.04vw;
}
.communiText{
    width: 26.4vw;
    font-size: 1.3vw;
    font-weight: 400;
    line-height: 1.98vw;
    letter-spacing: -0.015em;
    text-align: center;
    word-break: keep-all;
    margin: 0 auto;
}
.plaform_Desk{
    width: 78.96vw;
    margin-bottom: 10.416vw;
}
.footerContainer{
    background: #002233;
}
.footerWrapper{
     padding-top: 2.8vw;
}

.footerLogo{
    width: auto;
    height: 1.5vw;
    margin-right: 1.77vw;
    margin-left: 4.925vw;
    margin-top: 2.9vw; 
    /* width: auto;
    height: 2.198vw;
    margin-right: 1.77vw;
    margin-left: 5.2vw;  */
}

.footerHeader>span{
    /* width: 6vw; */
    margin: 0 1vw;
}
.footerHeader>span:first-child{
   margin-left: 0;
}
.footerBody{
    width: 70.73vw;
    border-bottom: 1px solid #FFFFFF;
    text-align: left;
    justify-content: space-between;
    font-size: 0.9vw;
}
.footerBody>div>div:first-child{
    margin-bottom: 3.3vw;
 }
.footerBody2{
    text-align: left;
}
.footerBody2>div:last-child{
    margin-bottom: 1.04vw;
 }
 .footerCopyrights{
    margin: 1.04vw 0 2.6vw 0;
    text-align: left;
 }
 .footerContainer .footerBtn{
    box-sizing: border-box;
    border: 1px solid #FFFFFF;
    border-radius: 0.26vw;
    width: 7.96vw;
    height: 2.34vw;
    font-size: 0.78vw;
    text-align: center;
    line-height: 2.34vw;
 }
 .tempvideo{
    position: absolute;
    left: 0;
    top: 71.5vw;
    width: 100%;
    height: auto;
 }
 .widePcf{
    display: flex;
}
 .wideMobile, .wideMobilef, .ipadMini, .ipadMinif{
    display: none;
}
 @media screen and (max-width:768px) {
    /* 아이패드 미니 세로 기준 */
        .topNav{
            height: 80px;
            padding: 3.125vw;
            font-size: 2.083vw;
        }
        .topNav>.topMenuList>div{
            font-size: 2vw;
            font-weight: 500;
        }
        .topNav>div>span{
            font-size: 1.5vw;
            font-weight: 500;
        }
        .fullImage{
            background-size: auto 100%;
            height: calc(100vh - 80px);
            min-height: 600px;
        }
        .topTitle{
            top: 40vh;
            left : 3.125vw;
        }
        .logo2{
            width: 41vw;
        }
        .topTitleMain{
            font-size: 3.5vw;
        }
        .topDownlod {
            font-size: 2vw;
            margin-bottom: 2vw;
        }
        .tempFull>.topTitleMain{
            margin-bottom: 4.5vw;
            visibility: hidden;  
        }
        .topBtnIcon{
            width: 19vw !important;
            height: auto;  
        }
        .topBtnIcon:first-child{
            margin-right: 2vw;
        }
        .IntroTextImage{
            margin-top: 22.6vw;
            margin-bottom: 3.9vw;
            width: 66vw;
        }
        .centerText{
            font-size: 3.2vw;
            height: 29.3vw;
            line-height: 4.86vw;
            margin-bottom: 12vw;
        }
        .bodyContents{
            background-image: url('./images/03_function_bg_pad.png');
            background-position: top;
            background-repeat: no-repeat;
            background-size: 100% auto;
            height: 349.9375vw
        }
        .bodyContents>div>.centerText{
            margin-bottom: 0;
        }
        .bodyContentContainer{
            width: 83.854vw;
            height: 356vw; 
            background-image: url('./images/03_M_line2_pad.png');
            background-position: top;
            grid-template-rows: 18.5% 33.5% 21.5% 21%;
            grid-template-columns: repeat(auto-fill, minmax(100%, auto));
            /* background-size: 100% 77.87%; */
            margin: 0 13.02vw;
            padding: 0 4.948vw;
            background-position-y : 4.078vw
        }
        .bodyContentItem>img{
            width: 8.156vw;
            margin-bottom: 2.604vw;
        }
        .bcMainText{
            font-size: 4.1vw;
            font-weight: 300;
            margin-bottom: 2.5vw;
        }
        .bodyContentItem3>.bcMainText{
            font-size : 3.7vw;
        }
        .bcBlueText{
            font-size: 2.6vw;
            font-weight: normal;
            margin-bottom: 1.2.vw;
        }
        .bcText{
            width: 50vw;
            font-size: 2.6vw;
            font-weight: 300;
            letter-spacing: -0.03rem;
        }
        .bodySlideList{
            /* height: 300vh; */
            /* grid-template-rows: repeat(auto-fill, minmax(100%, auto)); */
        }
        .bodySlideRow{
            grid-template-columns : 1fr;
            /* height: 100vh; */
            margin-bottom: 8vh;
        }
        .bodySlideItem{
            width: 100vw;
            
            grid-template-columns: repeat(auto-fill, minmax(50%, auto));
        }
        .textItem2{
            grid-row: 2;
            padding: 0 18vw 0 12vw;
            height: 45vh;
        }
        .Slideimg1{
            grid-row: 1;
            height: 45vh;
        }
        .bodyImg{
            height: 45vh;
            width: auto;
        }
        .slideMainText{
            font-size: 4.1vw;
            margin-bottom: 3.125vw;
        }
        .slideBlueText{
            font-size: 2.7vw;
            font-weight: 400;
            margin-bottom: 1.3vw;
        }
        .slideText{
            font-size: 2.7vw;
            font-weight: 300;
        }
        .centerTitle{
            font-size: 5.25vw;
            margin-top: 21vw;
            margin-bottom: 3.9vw;
        }
        .communiTitleGroup>.centerText{
            margin-bottom: 0;
            height: 9.3vw;
        }
        .centerText3{
            font-size: 3.2vw;
            width: 77vw;
            margin: 0 auto 13.02vw;
        }
        .centerText4{
            margin-bottom: 10vh !important;
        }
        .communiBody{
            overflow-y: scroll;
            justify-content: flex-start;
            margin: 4vh 0 14vh 3vw;
            padding: 4vh 0;
        }
        .communicard{
            /* width: 77.08vw; */
            width: 67vw;
            height: 70vw;
            /* height: 80.73vw; */
            padding: 2.60vw;
            margin: 0 1.5625vw;
            letter-spacing: -0.1em;
        }
        .communicard:first-child{
            margin-left: 0;
        }
        .communiTitle{
            /* font-size: 5.208vw; */
            font-size: 4.5vw;
            font-weight: 500;
            margin: 6.4vw auto 3vh;
        }
        .communiText{
            /* font-size: 3.255vw; */
            font-size: 2.8vw;
            width: 100%;
            line-height: 4.5vw;
            justify-content: center;
        }
        .communiBody::-webkit-scrollbar {
            /* width: 2px; */
            width: 0px;
            height: 2px;
        }
        /* Track */
        .communiBody::-webkit-scrollbar-track {
        background: #B6B6B6;
        }
        /* Handle */
        .communiBody::-webkit-scrollbar-thumb {
        background: #00A9FF;
        }
        /* Handle on hover */
        .communiBody::-webkit-scrollbar-thumb:hover {
        background: #00A9FF;
        }
        .platformTitleWrapper{
            height: 80vh;
          }
        .platformTitleGroup>.centerTitle{
            margin-top: 23vw;
            font-size: 5vw;
        }
        .platformTitleGroup>.centerText {
            font-size: 3.2vw;
            font-weight: 300;
            height: 0.3vw;
            width: 100%;
        }
        .plaform_Desk{
            width: 100%;
            height: auto;
        }
        .footerWrapper{
            padding: 6.51vw 3.125vw 0 4vw; 
            font-weight: 300;
        }
        .footerContainer{
            flex-direction: column;
        }
        .footerLogo{
            margin: 0;
            width: 19vw;
            height: auto;
            margin-bottom: 6.51vw;
        }
        .footerHeader{
            font-size: 1.953vw;
            width: 40vw;
            font-weight: 700;
        }
        .footerHeader>span{
            margin: 0 2vw;
            font-weight: 400;
        }
        
        .footerBody{
            width: 100%;
        }
        .footerBody2{
            font-size: 1.953vw;
            font-weight: 300;
            margin-bottom: 2.6vw;
        }
        .footerCopyrights{
            font-size: 1.953vw;
            width: 46vw;
            margin: 2.604vw 0 8.07vw;
        }
        .footerLogoWrapper{
            display: flex;
            width: 100%;
            justify-content: space-between;
            text-align: left;
        }
        .footerLogoWrapper>.footerBtn{
            width: 19.92vw;
            height: 5.86vw;
            line-height: 5vw;
            font-size: 1.953vw;
        }
        .widePc, .wideMobile, .widePcf, .wideMobilef{
            display: none;
        }
        .ipadMini{
            display: block;
        }
        .ipadMinif{
            display: flex;
        }
    }
@media screen and (max-width:599px) {
    .tempFull>.topTitleMain{
        margin-bottom: 3vw;
        visibility: hidden;  
    }
    .ant-back-top{
        left: 85vw !important;
        bottom: 3vh !important;
    }
    .topNav{
        padding: 3.3vh 4.67vw;
    }
    .topNav>div{
        align-items: center;
        font-weight: 900;
        width: 100%;
        padding-right: 2%;
        justify-content: flex-end;
    }
    .topNav>div>img{
        padding: 1vw;
    }
    .topNav, .toggleTopNav{
        height: 80px;
    }
    .topTitle{
        top : 35vh;
    }
    .menuBtn{
        width: 3vw;
    }
    .menuBtn>img{
        height: 100%;
        width: auto;
    }
    .lockScroll{
        overflow: hidden;
    }
    .toggleMenuWrapper{
        position: fixed;
        width: 100vw !important;
        height: 100vh;
        top:0;
        background-color: #FFFFFF;
        color: black;
        z-index: 3;
    }
    .toggleMenuWrapper>.topNav{
        background-color: #FFFFFF;
        color: black;
        border-bottom: 1px solid#C2C2C2;
    }
    .toggleMenuWrapper>.toggleMenuList{
        background-color: #FFFFFF;
        color: black;
        height: calc(100vh - 80px);
        overflow: scroll;         
    }
    .toggleMenuList{
        margin-top: 2vh;
    }
    .toggleMenuList>.toggleMenuItem{
        font-size: 5.5vw;
        font-weight: 700;
        height: 17vw;
        line-height: 17vw;
        text-align: left;
        padding : 0 8vw;
        border-radius: 7px;
    }
    .toggleMenuList>.toggleMenuItem:hover, .toggleMenuList>.toggleMenuItem:active {
        color: #FFFFFF;
        background-color: black;
    }
    .inactive{
        -webkit-transition:left 1s;
        transition:left 1s;
        transition-timing-function: ease;
        left: 100vw ;
    }
    .active{
        -webkit-transition:left 1s;
        transition:left 1s;
        transition-timing-function: ease;
        left : 0;
    }
    .logo2{
        width: 70vw;
        margin-bottom: 2vw;
    }
    .topTitleMain {
        font-size: 6vw;
        margin-bottom: 13.916vw;
        font-weight: 300;
    }
    .topDownlod {
        font-size: 4.8vw;
        font-weight: 500;
        margin-bottom: 3.83vw;
    }
    .topBtnIcon:first-child{
        margin-right: 4vw;
    }
    .IntroText{
        /* height: 100vh; */
    }
    .topBtnIcon {
        width: 44vw !important;
        height: auto;
    }
    .IntroTextImage {
        margin-top: 57.6vw;
        margin-bottom: 8.9vw;
        width: 78vw;
    }
    .IntroTextImage2 {
        margin-top: 56.6vw;
        margin-bottom: 11vw;
        width: 78vw;
    }
    .communiTitleGroup>.centerText {
        font-size: 4.6vw;
        height: 18.3vw;
        line-height: 7.5vw;
        font-weight: 300;
        letter-spacing: -0.5px;
    }
    .centerText{
        font-size:4.5vw;
        line-height: 7vw;
    }
    .centerText2{
        margin-bottom: 20vw !important;
    }
    .platformTitleGroup>.centerText{
        height: 13.3vw;
        font-size : 4.5vw;
        padding : 0 18vw;
        line-height: 7vw;
    }
    .bodyContents {
        background-image: url('./images/03_function_bg_mobile.png');
        height: 738.9375vw;
    }
    .bodyContentContainer{
        background-image: url('./images/03_M_line2_mobile.png');
        margin: 0;
        padding: 0;
        width: 90vw;
        height: 428vw;
        grid-template-rows: 25% 52% 24% 21%;
        background-position-y: 44vw;
    }  
    .bodyContentItem>img {
        width: 21vw;
        margin-bottom: 5vw;
    }
    
    .bcMainText {
        font-size: 6.7vw;
        font-weight: 400;
        margin-bottom: 8vw;
    }
    .bcBlueText {
        font-size: 4.5vw;
        font-weight: 400;
        margin-bottom: 2vh;
    }
    .bcText {
        width: 83vw;
        font-size: 4.6vw;
        font-weight: 300;
    }
    .Slideimg1{
        grid-row: 1;
        height: 100%;
    }
    .bodyImg{
        height: 100%;
    }
    .bodyImg>img {
        height: auto;
        width: 100vw;
    }
    .slideMainText {
        font-size: 8vw;
        font-weight: 400;
        margin-bottom: 6vw;
        letter-spacing: -0.1rem;
        
    }
    .textItem2 {
        padding: 0 7vw;
        height: 120vw;
    }
    .textItem3{
        /* padding-right: 9vw; */
    }
    .bodySlideList {
        /* height: 300vh; */
    }
    .bodySlideRow {
        /* height: 75vh; */
        margin-bottom: 15vh;
    }
    .wideMobile>.slideMainText2{
        margin-bottom: 2.125vw;
    }
    .slideBlueText {
        font-size: 4.8vw;
        font-weight: 400;
        margin-bottom: 3vh;
    }
    .slideText {
        font-size: 4.2vw;
        font-weight: 300;
        padding-right: 5vw;
    }
    .slideText{
        padding-right:0;
    }
    .communicard{
        width : 77vw;
        height : 81vw;
    }
    .communiTitle{
        font-size : 5vw;
        font-weight: 400;
    }
    .centerTitle, .platformTitleGroup>.centerTitle{
        margin-top: 30vw;
        margin-bottom: 7.9vw;
        font-size: 8vw;
    }
    .platformTitleWrapper{
        height : 100vh;
    }
    .footerLogoWrapper{
        flex-direction: column;
        width: 100%;
        justify-content: space-between;
    }
    .footerLogoWrapper>.footerBtn {
        width: 44vw;
        height: 12.5vw;
        line-height: 12.5vw;
        font-size: 4.2vw;
        margin-bottom: 10vw;
        border-radius: 5px;
    }
    .footerLogo {
        width: 33vw;
        margin-bottom: 8.51vw;
        margin-top: 8vw;
    }
    .footerHeader {
        font-size: 4vw;
        width: 76vw;
        font-weight: 700;
        margin-bottom: 0.3vw;
    }
    .footerHeader>span {
        margin: 0 3vw 6vw;
        padding: 0 3vw;
        font-weight: 500;
    }
    .footerHeader>span:first-child{
        padding-left: 0;
    }
    .footerBody2 {
        font-size: 4vw;
        font-weight: 300;
        margin-bottom: 4.6vw;
    }
    .footerCopyrights {
        font-size: 4vw;
        width: 100%;
        font-weight: 300;
        margin: 4.6vw 0 22vw;
    }
    .widePc, .ipadMini, .widePcf, .ipadMinif{
        display: none !important;
    }
    .wideMobile{
        display: block;
    }
    .wideMobilef{
        display: flex;
    }
    }   

    @media (orientation: landscape){
        .slideMainText{
            margin-bottom: 1vw;
        }
        .topNav{
            /* padding: 3vw; */
        }
        .platformTitleWrapper{
            height: auto;
        }
        .Slideimg1{
            /* grid-row: 1; */
            height: 100vh;
        }
        .bodyImg{
            height: 100vh;
            width: auto;
        }
        .bodyImg>img{
            margin: 0 auto;
            width: 100%;
            height: auto;
        }
        .textItem2{
            height: auto;
            min-height: 100vh;
            padding: 8.6vh inherit;
            /* grid-row: 2; */
        }
        .slideMainText{
            font-size: 3.6vw;
        }
        .slideBlueText, .slideText{
            font-size: 2.4vw;
        }
    }
    
    @media (orientation: landscape) and (min-width:768px) {
        .bodySlideRow{
            grid-template-columns : 1fr;
        }
        .bodySlideRow>div{
           width: 100vw;
        }
        .Slideimg1{
           grid-row: 1;
        }
        .textItem2{
            grid-row: 2;
        }
        .slideMainText{
            font-size: 2.9vw;
            margin-bottom: 2.5vw;
        }
        .slideBlueText, .slideText{
            font-size: 2.2vw;
        }
    }
    @media (orientation: landscape) and (min-width:960px) {
        .fullImage{
            min-height: unset;
        }
        .topNav{
            /* padding: 2.5vw; */
        }
        .bodySlideRow{
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(50%, auto));
            gap: 0;
        }
        .bodySlideRow>div{
            width: 50vw;
         }
        .Slideimg1{
            grid-row: unset;
        }
        .textItem2{
            grid-row: unset
        }
    }
    @media (orientation: landscape) and (min-height:375px){
        .Slideimg1{
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        .bodyImg{
            height: auto;
        }
        .textItem2{
            /* min-height: unset; */
            /* padding: 3.6vw 19vw 3.6vw 13.5vw; */
        }
        
        
    }
    @media (orientation: landscape) and (min-height:480px) {
        .slideMainText{
            font-size: 1.8vw;
        }
        .slideBlueText, .slideText{
            font-size: 1.2vw;
        }
        .textItem2{
            min-height: unset;
            padding: 4.6vw 8.5vw;
        }
    }
    