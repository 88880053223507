/* ==================== 가로 버전(1536px/864px) ==================== */
.tempAroundBorder {
	/* border: 1px solid rgba(230, 230, 230, 0.5); */
	width: 1536px;
	height: 864px;
	/* box-sizing: content-box;  */
}

.fullLayout {
	display: grid;
	grid-template-columns: 256px auto;
	height: 100%;
}

.offProgress {
	display: none;
}

.onProgress {
	position: fixed;
	z-index: 5;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100vw;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	padding-left: 29%;
	padding-right: 28%;
}

.onProgressText {
	color: white;
	font-weight: 400;
	font-size: 30px;
	line-height: 20px;
	text-align: center;
	margin-bottom: 33px;
}

.onProgressText2 {
	color: white;
	font-weight: 400;
	font-size: 20px;
	height: 20px;
	text-align: center;
	margin-top: 23px;
}

.onProgressLayout {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	border-radius: 10px;
	padding-bottom: 2.5px;
	padding-left: 2px;
	padding-right: 2px;
	height: 12.5px;
}

.categoryLayout {
	display: grid;
	grid-template-rows: auto 153px;
	padding-left: 65px;
}

.categoryContent {
	height: 100%;
	padding-top: 61px;
	padding-left: 24px;
	padding-right: 20px;
	font-size: 20px;
	color: #292929;
}

.categoryItem {
	margin-bottom: 26px;
	cursor: pointer;
	color: #292929
}

.categoryItem:hover {
	color: #00A9FF;
}

.categoryItem:active {
	font-weight: 700;
}

.categoryFooter {
	height: 153px;
	padding-top: 48px;
	padding-bottom: 48px;
	padding-left: 20px;
	padding-right: 20px;
	font-size: 16px;
}

.mainLayout {
	display: grid;
	grid-template-rows: 108px 68px 538px 150px;
}

.headerLayout {
	display: grid;
	grid-template-columns: 50% 50%;
	padding-top: 51px;
	padding-bottom: 12px;
	padding-left: 46px;
	padding-right: 56px;
}

.headerButtons {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	cursor: pointer;
}

.searchLayout {
	display: grid;
	width: 464.45px;
	grid-template-columns: 48px auto 48px;
	height: 45px;
	background: #F0F0F0;
	/* border: 1px solid #DBDBDB; */
	border-radius: 10px;
}

.searchLayout:focus-within {
	border: 2px solid #00A9FF;
	/* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3); */
}

.searchLayout #ui_ic_close_blue {
	visibility: hidden;
}

.searchLayout:focus-within #ui_ic_close_blue {
	visibility: visible;
}

.searchIconLayout {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	font-size: 21px !important;
	color: #8F8F8F;
}

.searchText {
	display: flex;
	width: 100%;
	height: 100%;
	background: none;
	border: none;
	outline: none;
	color: #5C5C5C;
	font-size: 16px;
	line-height: 20px;
}

.searchText::placeholder {
	color: #8F8F8F;
	font-family: 'Noto Sans KR';
}

.headerIconLayout {
	margin: 0;
	margin-left: 40px;
	font-size: 40px !important;
	color: #292929;
}

.headerIconLayout:hover {
	color: #33BAFF;
}

.headerIconLayout:active {
	color: #00A9FF;
}

.headerSelectIconLayout {
	margin: 0;
	margin-left: 40px;
	font-size: 40px !important;
	color: #5C5C5C;
}

.sortLayout {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 16px;
	padding-bottom: 16px;
	padding-left: calc(46px - 8px);
	padding-right: 56px;
}

.sortLayoutL {
	display: flex;
	justify-content: space-between;
	width: 224px;
	margin-left: 10px;
}

.sortByLayout {
	height: 36px;
	width: 108px;
	border-radius: 10px;
	border: 2px solid #00A9FF;
	display: flex;
	justify-content: center;
	align-items: center;
}

.sortByLayoutClicked {
	height: 36px;
	width: 108px;
	border-radius: 10px;
	border: 2px solid #00A9FF;
	background: linear-gradient(0deg, #00A9FF 0%, #00A9FF 100%), #FFF;
	display: flex;
	justify-content: center;
	align-items: center;
}

.sortBy1 {
	width: 24px;
	height: 24px;
	margin-right: 8px;
	content: url("../images/ui_ic_image.png");
}

.sortBy1Clicked {
	width: 24px;
	height: 24px;
	margin-right: 8px;
	content: url("../images/ui_ic_image2.png");
}

.sortBy2 {
	width: 24px;
	height: 24px;
	margin-right: 8px;
	content: url("../images/ui_ic_menu2.png");
}

.sortBy2Clicked {
	width: 24px;
	height: 24px;
	margin-right: 8px;
	content: url("../images/ui_ic_menu.png");
}

.sortByText {
	color: #00A9FF;
	font-size: 16px;
	font-weight: 500;
}

.sortByTextClicked {
	color: #FFF;
	font-size: 16px;
	font-weight: 500;
}

.sortInLayout {
	position: relative;
	cursor: pointer;
}

.sortInText {
	color: #292929;
	font-size: 16px;
	font-weight: 500;
	display: flex;
	justify-content: center;
	align-items: center;
}

.sortInIcon {
	width: 24px;
	height: 24px;
	margin-left: 8px;
}

.sortModalMask {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	/* background-color: rgba(0, 0, 0, 0.3); */
	z-index: 10;
	display: block;
}

.sortModalMaskClosed,
.sortModalClosed {
	display: none;
}

.sortModal {
	position: absolute;
	top: 34px;
	right: 0;
	width: 125px;
	/* height: 224px; */
	padding: 4px 6px;
	z-index: 11;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-radius: 10px;
	border: 0.5px solid rgba(0, 0, 0, 0.10);
	background: #FFF;
	box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.10);
}

.sortModalBorder {
	border-top: 1px solid rgba(0, 0, 0, 0.10);
}

.sortModalMenu {
	width: 108px;
	/* height: 36px; */
	padding: 5px;
	color: #292929;
	font-size: 15px;
	font-weight: 400;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.sortModalMenu,
.sortModalMenuChecked {
	padding-left: 16px;
	position: relative;
}

.sortModalMenuChecked::before {
	content: '';
	width: 4px;
	height: 4px;
	border-radius: 40px;
	background-color: black;
	position: absolute;
	top: 16px;
	left: 4px;
}

.sortModalMenu:hover {
	border-radius: 5px;
	background: var(--yt-gray-100, #F5F5F5);
}

.bodyLayout {
	display: flex;
	padding-left: calc(46px - 8px);
	padding-right: calc(56px - 16px - 8px);
	flex-wrap: wrap;
	align-content: flex-start;
	overflow-y: auto;
	overflow-x: hidden;
	text-align: center;
}

.bodyLayout::-webkit-scrollbar {
	width: 8px;
}

.bodyLayout::-webkit-scrollbar-track {
	background-color: transparent;
}

.bodyLayout::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: #D9D9D9;
}

.noCardLayout {
	display: flex;
	width: 100%;
	height: 100%;
	background-color: #F0F0F0;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
}

.emptyCardLayout {
	display: flex;
	width: 100%;
	height: 100%;
	background-color: #ffffff;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
}

.cardLayout,
.cardLayout2 {
	display: grid;
	grid-template-rows: 136px auto 20px;
	margin: 8px;
	/* padding-top: 8px;*/
	padding-bottom: 2px;
	padding-left: 5px;
	padding-right: 5px;
	width: 224px;
	height: 224px;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	box-sizing: border-box;
	cursor: pointer;
}

.selectCardLayout {
	border: 2px solid #00A9FF;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
	box-sizing: border-box;
	padding-top: 3px;
	/* padding-top: 6px;
	padding-bottom: 6px; */
	padding-left: 3px;
	padding-right: 3px;
	background: #E5F6FF;
	position: relative;
}

.cardLayout:hover {
	border: 2px solid #00A9FF;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
	box-sizing: border-box;
	padding-top: 3px;
	/* padding-top: 6px;
	padding-bottom: 6px; */
	padding-left: 3px;
	padding-right: 3px;
}

.cardCheckIcon {
	margin-left: 3.5px;
	width: 33px;
	height: 33px;
	position: absolute;
	right: 5px;
}

.cardImageLayout {
	display: flex;
	justify-content: center;
	height: 100%;
}

.cardImage {
	width: 136px;
	height: 136px;
	border: 1px solid #DBDBDB;
	border-radius: 10px;
	box-sizing: border-box;
}

img.cardImage {
	background: transparent;
}

div.cardImage {
	background: #DBDBDB;
	background-image: url("../images/file_loading.png");
	background-position: 50% 50%;

}

.cardImageInTable {
	width: 32px;
	height: 32px;
	background: #DBDBDB;
	border-radius: 10px;
	margin-right: 14px;
}

.cardTitle {
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	max-height: 54px;
	padding-bottom: 4px;
	word-wrap: break-word;
	word-break: break-all;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 500;
	font-size: 16px;
	line-height: 110%;
	color: #292929;
	margin-top: auto;
	margin-bottom: auto;
}

.cardCap {
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	color: #8F8F8F;
	padding-top: 5px;
}

.footerLayout {
	padding-top: 30px;
	padding-bottom: 10px;
	padding-left: 46px;
	padding-right: 56px;
	text-align: center;
}

.footerLayout2 {
	display: grid;
	grid-template-rows: 55px 46px;
	padding-top: 24px;
	padding-bottom: 36px;
	padding-left: 46px;
	padding-right: 56px;
	text-align: center;
}

.footerTitle {
	font-size: 16px;
}

.footerLayout2 .footerBtn {
	display: flex;
	justify-content: center;
	align-items: center;
}

.footerBtn2:active {
	border-color: #00A9FF !important;
	background: #00A9FF !important;
}

.fileConfirmBtn {
	width: 130px;
	height: 46px;
	color: white;
	background: #00A9FF;
	border-radius: 30px;
	border: none;
	font-weight: 500;
	font-size: 24px;
	cursor: pointer;
}

.fileConfirmBtn:disabled {
	background: #8F8F8F;
	cursor: default;
}

.fileConfirmBtn:hover {
	background: #006599;
}

.fileConfirmBtn:hover:disabled {
	background: #8F8F8F;
	cursor: default;
}

.fileNameInTable {
	width: 725px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	/* 말줄임 적용 */
}

.tableLayout {
	width: 97%;
	margin-left: 10px;
}

.tableLayoutFontLeft {
	font-size: 16px;
	text-align: left;
	font-weight: 500;
	color: #292929;
}

.tableLayoutFontRight {
	font-size: 16px;
	text-align: right;
	font-weight: 500;
	color: #292929;
}

.fileExternCell {
	width: 59px;
}

.fileSizeCell {
	width: 70px;
}

.uploadTimeCell {
	width: 135px;
}

.tableLayout th,
.tableLayout td {
	height: 48px;
}

.tableLayout tr td.ant-table-selection-column,
.tableLayout tr th.ant-table-selection-column {
	/* display: none !important; */
}

/* ==================== 세로 버전(864px/1536px) ==================== */

@media screen and (max-width: 864px) {
	.tempAroundBorder {
		/* border: 1px solid slategray; */
		width: 864px;
		height: 1536px;
		/* box-sizing: content-box;  */
	}

	.fullLayout {
		display: grid;
		grid-template-columns: 240px auto;
		height: 100%;
	}

	.categoryLayout {
		display: grid;
		grid-template-rows: auto 153px;
		padding-left: 0px;
	}

	.categoryContent {
		height: 100%;
		padding-top: 61px;
		padding-left: 64px;
		padding-right: 20px;
		font-size: 20px;
		color: #292929;
	}

	.categoryFooter {
		height: 153px;
		padding-top: 48px;
		padding-bottom: 48px;
		padding-left: 64px;
		padding-right: 0px;
		font-size: 16px;
	}

	.mainLayout {
		display: grid;
		grid-template-rows: 102px 68px 1198px 168px;
		/* grid-template-rows: 128px 1240px 168px; */
	}

	.headerLayout {
		display: grid;
		grid-template-columns: 50% 50%;
		padding-top: 48px;
		padding-bottom: 12px;
		padding-left: 0px;
		padding-right: 40px;
	}

	.searchLayout {
		width: auto;
	}

	.sortLayout {
		padding-right: 40px;
		padding-left: 0px;
	}

	.sortLayoutL {
		margin-left: 0px;
	}

	.bodyLayout {
		display: flex;
		padding-left: 0px;
		padding-right: calc(40px - 8px);
		flex-wrap: wrap;
		align-content: flex-start;
		overflow-y: auto;
		text-align: center;
	}

	.cardLayout {
		display: grid;
		grid-template-rows: 172.5px auto 18px;
		margin: 0;
		padding-top: 10px;
		padding-bottom: 10px;
		padding-left: 55px;
		padding-right: 55px;
		width: 284px;
		height: 284px;
		justify-content: start;
		align-items: baseline;
		box-sizing: border-box;
		cursor: pointer;
	}

	.selectCardLayout {
		border: 2px solid #00A9FF;
		box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
		box-sizing: border-box;
		padding-top: 8px;
		padding-bottom: 8px;
		padding-left: 55px;
		padding-right: 55px;
		background: #E5F6FF;
	}

	.cardLayout:hover {
		padding-top: 8px;
		padding-bottom: 8px;
		padding-left: 55px;
		padding-right: 55px;
	}

	.cardCheckIcon {
		margin-left: 5px;
		width: 42px;
		height: 42px;
	}

	.cardImage {
		width: 172.5px;
		height: 172.5px;
		background: #DBDBDB;
		border-radius: 10px;
	}

	.cardImageInTable {
		width: 32px;
		height: 32px;
	}

	.cardTitle {
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		max-height: 75px;
		padding-top: 4px;
		padding-bottom: 4px;
		word-wrap: break-word;
		word-break: break-all;
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: 500;
		font-size: 20.5px;
		/* line-height: 100%; */
		color: #292929;
	}

	.cardCap {
		font-weight: 500;
		font-size: 15.4286px;
		line-height: 18px;
		color: #8F8F8F;
	}

	.fileNameInTable {
		width: 150px;
	}

	.footerLayout {
		padding-top: 30px;
		padding-bottom: 10px;
		padding-left: 40px;
		padding-right: 40px;
		text-align: center;
	}

	.footerLayout2 {
		display: grid;
		grid-template-rows: auto 82px;
		padding-top: 30px;
		padding-bottom: 10px;
		padding-left: 26px;
		padding-right: 56px;
		text-align: center;
	}

	.tableLayout {
		width: 600px;
	}
}

/* ==================== antd 오버라이딩 ==================== */

.ant-progress-bg {
	height: 9px !important;
}






/* ==================== 전시회 임시 ==================== */
.tempAroundBorder .ant-btn-primary{
	background: rgba(0, 169, 255, 1);
	border: rgba(0, 169, 255, 1);
}
.galleryTabHeader {
	font-size: 14px;
	font-weight: 500;
	line-height: 20.27px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: rgba(117, 117, 117, 1);
}

.galleryMenu .ant-menu-submenu-title {
	font-size: 20px;
	font-weight: 700;
	line-height: 24px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	justify-content: flex-start;
	padding-left: 0px !important;
}

.galleryMenu .ant-menu-light .ant-menu-item-active,
.galleryMenu .ant-menu-light .ant-menu-item:hover,
.galleryMenu .ant-menu-light .ant-menu-submenu-active,
.galleryMenu .ant-menu-light .ant-menu-submenu-title:hover,
.galleryMenu .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open .galleryMenu .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.galleryMenu .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon,
.galleryMenu .ant-menu-submenu-open,
.galleryMenu .ant-menu-submenu-open .ant-menu-submenu-arrow {
	color: rgba(0, 169, 255, 1);
}

.galleryMenu .ant-menu-submenu-arrow {
	display: none;
}

.galleryMenu.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
	border-right: none;
}

.galleryMenu .ant-menu-item,
.galleryMenu .ant-menu-submenu-title {
	height: 44px !important;
}

.galleryMenu .ant-menu-item.ant-menu-item-only-child {
	font-size: 20px;
	font-weight: 400;
	line-height: 24px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	padding-left: 24px !important;
}

.galleryMenu .ant-menu-sub.ant-menu-inline,
.galleryMenu .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: transparent;
}

.galleryMenu .ant-menu-inline .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after,
.ant-menu-vertical .ant-menu-item:after {
	border-right: none;
}

.categoryLayoutG {
	padding-left: 60px;
}

.categoryContentG {
	height: 100%;
	padding-top: 61px;
	padding-left: 24px;
	padding-right: 20px;
	font-size: 20px;
	color: #292929;
}

.orderDetailWrapper {
	overflow-y: scroll;
	padding-left: 46px;
	padding-right: 50px;
}

.headerIconS {
	margin: 0;
	margin-left: 40px;
	font-size: 35px !important;
	color: rgba(0, 0, 0, 1);
}

.footerLayoutG {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: 20px;
	text-align: center;
	padding: 40px;
}

.mainLayoutTemp {
	display: grid;
	grid-template-rows: 108px 606px 150px;
}

.cardImageG {
	width: 136px;
	height: 136px;
	border: 1px solid transparent;
	border-radius: 10px;
	box-sizing: border-box;
}

.sortByLayoutG {
	height: 40px;
	width: 96px;
}

.sortLayoutLG {
	width: 200px;
}

.cardLayoutG {
	grid-template-rows: 150px auto 14px;
	height: 210px;
}

.galleryFlex {
	display: flex;
}

.galleryFlexC {
	display: flex;
	flex-direction: column;
}

.pdHeaderTitle {
	font-size: 32px;
	font-weight: 500;
	line-height: 40px;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	margin-top: 10px;
	margin-bottom: 20px;
}

.pdHeaderL {
	font-size: 16px;
	font-weight: 500;
	line-height: 23.17px;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	width: 115px;
}

.pdHeaderGray {
	color: rgba(92, 92, 92, 1);
}

.pdHeaderline {
	height: 0.5px;
	border-top: 1px solid black;
	margin-top: 20px;
	margin-bottom: 20px;
}

.pdHeaderMinus {
	border-right: none !important;
	border-radius: 10px 0 0 10px;
}

.pdHeadercount {
	width: 40px;
	height: 40px;
	border: 1px solid rgba(219, 219, 219, 1);
	display: flex;
	justify-content: center;
	align-items: center;
}

.pdHeaderPlus {
	border-left: none !important;
	border-radius: 0 10px 10px 0;
}

.pdHeadercount.disabled {
	color: rgba(0, 0, 0, 0.4)
}

.pdHeaderTotalPrice {
	font-size: 20px;
	font-weight: 700;
	line-height: 28.96px;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
}

.galleryTab .ant-tabs-tab {
	margin: 0px !important;
	width: 280px;
	height: 48px;
	display: flex;
	justify-content: center;
}

.ant-tabs.galleryTab {
	margin-top: 40px;
}

.galleryTab .ant-tabs-tab .ant-tabs-tab-btn {
	font-size: 16px;
	/* font-weight: 500; */
	line-height: 40px;
	text-align: center;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: black;
}

.galleryTab .ant-tabs-tab:first-child {
	border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.galleryTab .ant-tabs-tab {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.galleryTab .ant-tabs-tab.ant-tabs-tab-active {
	border-bottom: 1px solid white;
	border-top: 2px solid black;
}

.galleryTab .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
	background-color: transparent;
}

.galleryTab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: black;
}

.pdt_topWrapper {
	background: rgba(245, 245, 245, 1);
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 40px;
	margin-top: 30px;
	margin-bottom: 80px;
}

.pdt_topCon1 {
	font-size: 20px;
	font-weight: 500;
	line-height: 24px;
	text-align: center;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	margin-bottom: 20px;
}

.pdt_topCon2 {
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	text-align: center;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;

}

.pdt_bodyTitle1 {
	font-size: 24px;
	font-weight: 500;
	line-height: 24px;
	text-align: center;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	margin-bottom: 30px;
}

.pdt_bodyTitle2,
.pdt_bodyTitle3 {
	font-size: 16px;
	font-weight: 400;
	text-align: center;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
}

.pdt_bodyTitle3 {
	margin-bottom: 100px;
}

.pdt_fileSrc1 {
	margin-bottom: 20px;
}

.pdt_fileSrc1Desc {
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	text-align: center;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	color: rgba(143, 143, 143, 1);
	margin-bottom: 100px;
}
.pdt_fileSrc2{
	width: 600px;
	margin-bottom: 20px;
}
.pdt_fileSrc4{
	margin-bottom: 80px;
}
.fileDetail{
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	text-align: center;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
}
.galleryColl.ant-collapse-borderless>.ant-collapse-item{
	background-color: #FFF;
	border-bottom: none;
}
.galleryColl.ant-collapse>.ant-collapse-item>.ant-collapse-header{
	flex-direction: row-reverse;
	border-bottom: 1px solid rgba(219, 219, 219, 1)
}
.galleryColl.ant-collapse>.ant-collapse-item>.ant-collapse-header{
	font-size: 20px;
	font-weight: 500;
	line-height: 40px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
}
.galleryColl.ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box{
	border-bottom: 1px solid rgba(219, 219, 219, 1);
	padding: 20px 16px 40px;
}
.galleryColl .collWrapper{
	display: flex;
	align-items: flex-start;
	font-size: 14px;
	font-weight: 400;
	line-height: 18.2px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	margin-bottom: 20px;
}
.galleryColl .collWrapper>div:first-child{
	font-weight: 500;
	width: 100px;
}
.orderDetailContainer{
	padding-top: 10px ;
}
.odpHeader{
	border-bottom: 1px solid black;
	font-size: 20px;
	font-weight: 500;
	line-height: 40px;
	text-align: left; 
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	padding-bottom: 10px;
	margin-bottom: 20px 
}
.orderDetailContainer .collWrapper{
	display: flex;
	align-items: flex-start;
	font-size: 16px;
	font-weight: 400;
	line-height: 18.2px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	margin-bottom: 20px;
}
.orderDetailContainer .collWrapper>div:first-child{
	width: 120px;
}
.orderDetailContainer .collWrapper>div:last-child{
	font-weight: 500;
}
.orderDetailContainer .collWrapper .ant-select-selector,
.orderDetailContainer .collWrapper .ant-input{
	border-radius: 10px !important;
}
.orderDetailContainer .ant-input-textarea-show-count:after{
	color: rgba(194, 194, 194, 1);
	font-size: 12px;
	font-weight: 500;
	margin-top: 5px;
}
.odpOrderlist{
	font-size: 16px;
	font-weight: 500;
	line-height: 23.17px;
	text-align: left;
	text-underline-position: from-font;
	text-decoration-skip-ink: none;
	margin-bottom: 100px;
}
.odpCardWrapper{
	color:  rgba(0, 0, 0, 0.3);
	border: 1px dotted rgba(219, 219, 219, 1);
	border-radius: 10px;
	width: 424px;
	height: 80px;
	justify-content: center;
	align-items: center;
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 20px;
}
.odpCardWrapper>div:last-child{
	width:84px;
	height: 32px;
	line-height: 28px;
	border : 1px solid  rgba(0, 0, 0, 0.3);
	border-radius: 24px;
	text-align: center;
	font-size: 14px;
}
.odpCardDesc{
	width: 424px;
	color: rgba(92, 92, 92, 1);
	margin-bottom: 7px;
	font-size: 12px;
}
.odpCardDesc>div:last-child{
	color:rgba(0, 0, 0, 0.3);
	text-decoration: underline;
}
.searchLayoutB {
	display: grid;
	width: 464.45px;
	grid-template-columns: 48px auto 48px;
	height: 45px;
	background: #F0F0F0;
	/* border: 1px solid #DBDBDB; */
	border-radius: 10px;
}