.applink>*{
    font-family: 'Noto Sans KR';
}
.applink p {
    margin: 0;
}

.applink .header {
    text-align: center;
    margin: 26px 0;
}

.applink p {
    display: block;
}

.applink .manual {
    height: 70px;
    background-color: #37558a;
}

.applink .manualWrapper {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    margin: 0 auto;
    position: relative;
}

.applink .manualTitle {
    font-family: 'Noto Sans KR';
    font-size: 24px;
    font-weight: 900;
    line-height: 34.75px;
    color: #ffffff;
    min-width: 210px;
}
.applink .manualDownload {
    display: flex;
    color: #ffffff;
    justify-content: flex-end;
    align-items: center;
    min-width: 210px;

    font-family: 'Noto Sans KR';
    font-weight: 500;
    font-size: 18px;
    line-height: 26.06px;
    text-decoration: none;

    p {
        text-decoration: underline;
    }

    img {
        margin-left: 8px;
    }
}

.applink .mainSection {
    position: relative;
    margin: 0 auto;
    width: 1200px;
    display: table;
    height: calc(100% - 15vh);
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1200px) {
    .applink .mainSection {
        width: 100%;
        display: flex;
        padding: 0 20px;
    }
}

.applink .mainWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    img {
        position: relative;
        left: -10px;
    }
}

.applink .contents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 466px;
    max-width: 370px;

    div:first-child {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .logo {
            width: 204px;
            height: 55px;
        }
    }

    div:last-child {
        margin-bottom: 20px;
    }
}

.applink .contentTxt1 {
    font-family: 'Noto Sans KR';
    font-size: 32px;
    font-weight: 700;
    line-height: 46.34px;
}

.applink .download {
    max-width: 387px;
    height: 57px;
    border-radius: 10px;
    background: #00a9ff;
    display: flex;
    color: #ffffff;
    justify-content: space-between;
    align-items: center;
    font-family: 'Noto Sans KR';
    font-size: 18px;
    font-weight: 700;
    line-height: 26.06px;
    text-decoration: none;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
    padding-left: 144px;
    padding-right: 16px;
}

.applink .loginTxt {
    font-family: 'Noto Sans KR';
    font-size: 24px;
    font-weight: 700;
    line-height: 34.75px;
    color: #ff0000;
}

.applink .download2 {
    font-family: 'Noto Sans KR';
    font-size: 16px;
    font-weight: 500;
    line-height: 23.17px;
    text-align: left;
    color: #000000;
}
