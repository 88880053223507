.ant-transfer-list-header-selected{
    display: none;
}
.ant-transfer-list-header-title{
    text-align: center !important;
}
.ant-modal-footer{
    border-top:none
}
/* .ant-btn ant-btn-default .columnBtn:hover{
    background-color: #B6B6B6;
} */
#columnBtn1:hover{
    color: black;
}