.starMark {
  color: red;
  font-size: 0.8em;
  padding-left: 0.2em;
}
.privacyWrapper {
  font-size: 1.25vw;
}
.privacyWrapper > .mainheader {
  font-size: inherit;
  font-weight: 600;
}
.privacyWrapper > .header {
  font-size: 0.95vw;
  margin-bottom: 0.5vw;
  font-weight: 500;
}
.privacyWrapper > .body {
  font-size: 0.85vw;
  padding-left: 0.2vw;
}
.InquiryWrapper .ant-modal-footer {
  text-align: center !important;
}
.InquiryWrapper .ant-modal-footer > button {
  padding-left: 5vw !important;
  padding-right: 5vw !important;
  font-size: 1vw !important;
  height: auto;
}
.inquiryImg {
  background-image: url('./images/07_contact_banner.png');
  justify-content: center;
  align-items: flex-start;
}
.inquiryTitle {
  position: absolute;
  top: 30%;
  left: 15%;
  align-items: flex-start;
  text-align: left;
}
.inquiryTitleMain {
  font-size: 3vw;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.inquiryTitleSub {
  font-size: 1.3vw;
  font-weight: 400;
  width: 25vw;
  word-break: keep-all;
}
.footerWrapper {
  color: white;
}
.InquiryWrapper .ant-modal {
  top: 15% !important;
}
.InquiryWrapper {
  width: 80%;
  max-width: 1080px;
  /* margin: 2vw auto; */
  margin: 0 auto;
  padding-top: 5vw;
}
.InquiryContainer {
  padding: 0 1rem;
}
.InquiryHeader {
  padding: 2vw 0 1.5vw;
  font-size: 2rem;
  font-weight: 900;
  border-bottom: 1px solid black;
}
.InquiryInputWrapper {
  /* margin: 1vw 0; */
  padding: 1vw 0;
  display: flex;
  align-items: center;
}
.InquiryInputWrapper:first-child {
  margin-top: 1vw;
}
.InquiryInputWrapperL {
  align-items: flex-start;
}
.InquiryInputWrapperB {
  border-bottom: 1px solid lightgray;
}
.InquiryInputHeader {
  width: 20%;
  font-size: 1.3rem;
}
.InquiryInputHeaderL {
  height: inherit;
}
.InquiryInputContainer {
  width: 80%;
  display: flex;
  align-items: center;
}
.InquiryCheckboxList {
  /* margin-left: 0.5rem; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(45%, auto));
  gap: 0.5rem;
}
.InquiryWrapper input::placeholder,
.InquiryWrapper .ant-input::placeholder,
.InquiryWrapper .ant-checkbox + span,
.InquiryWrapper .checkboxLabel {
  font-size: 1rem;
}
.checkboxLabel {
  margin-left: 1vw;
  word-break: keep-all;
  cursor: pointer;
}
.privacyRule {
  color: #1980ff;
}
.InquiryWrapper .ant-checkbox {
  transform: scale(1.5);
  margin: 0 0.5vw !important;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
  border: 3px;
}
.InquiryInputList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(45%, auto));
  gap: 1em;
}
.InquiryWrapper .ant-input,
.InquiryWrapper .textarea.ant-input {
  font-size: 1rem;
}
.InquiryWrapper .ant-input {
  padding: 0.4rem 0.7rem !important;
}
.InquiryWrapper textarea.ant-input {
  resize: none !important;
  min-height: 11.2rem !important;
}
.InquiryPrivacyWrapper {
  padding-top: 0;
}
.checkBoxNotouch {
  background-color: transparent;
  position: absolute;
  width: 2.2rem;
  height: 100%;
  z-index: 4;
}
.InquiryBtnWrapper {
  display: flex;
  justify-content: center;
  margin: 4vw 0 5vw;
}
.InquiryBtnWrapper > button {
  font-size: 1.4rem;
  padding: 0.5rem;
  /* width: 10rem; */
  width: 80%;
  height: 3.5rem;
  /* height: 4.5rem; */
  border-radius: 0.8rem;
}
.sendMailModalMask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.sendMailModalWrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  z-index: 1000;
}
.sendMailModal {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  font-size: 16px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  pointer-events: none;
  position: relative;
  top: 100px;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding: 0 0 24px;
  width: 314px;
}
.sendMailModalContent {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 10px 10px 11px 11px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
  height: 165px;
  padding-top: 49px;
}

.sendMailModalBtn {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #00a9ff;
  border-radius: 0 0 10px 10px;
  height: 45px;
  font-size: 16px;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: -0.5px;
  color: #ffffff;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .InquiryWrapper .ant-modal-footer > button {
    padding-left: 10vw !important;
    padding-right: 10vw !important;
    font-size: 2.5vw !important;
    height: auto;
  }
  .privacyWrapper {
    font-size: 3.7vw;
  }
  .privacyWrapper > .mainheader {
    font-size: inherit;
  }
  .privacyWrapper > .header {
    font-size: 2.8vw;
    margin-bottom: 1vw;
  }
  .privacyWrapper > .body {
    font-size: 2.3vw;
  }
  .inquiryImg {
    background-image: url('./images/07_contact_banner_ipad2.png');
    justify-content: center;
    align-items: flex-start;
    background-size: auto 100%;
    /* background-position: right 80%; */
  }
  .inquiryTitle {
    position: absolute;
    top: 35%;
    left: 10%;
    align-items: flex-start;
    text-align: left;
  }
  .inquiryTitleMain {
    font-size: 5.5vw;
    font-weight: 600;
    margin-bottom: 0.7rem;
  }
  .inquiryTitleSub {
    font-size: 3.8vw;
    font-weight: 400;
    width: 80vw;
    word-break: keep-all;
  }

  .InquiryWrapper {
    width: 90%;
    height: 100%;
    margin: 12vw auto;
  }
  .InquiryInputList {
    grid-template-columns: repeat(auto-fill, minmax(100%, auto));
  }
  .InquiryInputWrapper {
    /* padding: 2vw 0; */
  }
  .InquiryInputWrapper:first-child {
    margin-top: 4vw;
  }
  .InquiryInputWrapperL {
    padding-bottom: 2vw;
  }
  .InquiryBtnWrapper {
    margin: 1vw 0 2vw;
  }
  .InquiryBtnWrapper > button {
    margin: 6vw auto 4vw;
    width: 100%;
  }
  .ant-checkbox + span {
    padding-left: 1.5vw;
  }
  .checkboxLabel {
    margin-left: 1.5vw;
  }
}
@media screen and (max-width: 599px) {
  .InquiryWrapper .ant-modal-footer > button {
    padding-left: 12vw !important;
    padding-right: 12vw !important;
    font-size: 4.5vw !important;
    height: auto;
  }
  .inquiryTitle {
    position: absolute;
    top: 32%;
    left: 5%;
    align-items: flex-start;
    text-align: left;
  }
  .inquiryTitleMain {
    font-size: 10vw;
    font-weight: 600;
    margin-bottom: 1.2rem;
  }
  .inquiryTitleSub {
    font-size: 5.8vw;
    font-weight: 300;
    width: 59vw;
    word-break: keep-all;
  }

  .InquiryWrapper {
    width: 95%;
    margin-top: 5vw;
  }
  .InquiryHeader {
    font-size: 1.3rem;
    padding: 6vw 3vw;
  }
  .InquiryInputHeader {
    width: 35%;
    font-size: 1rem;
    margin: 0.5rem 0;
    word-break: keep-all;
  }
  .InquiryInputWrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 8vw;
  }
  .InquiryInputHeaderEmpty {
    display: none;
  }
  .InquiryInputContainer {
    width: 100%;
  }
  input::placeholder,
  .ant-input::placeholder,
  .ant-checkbox + span,
  .checkboxLabel {
    font-size: 0.8rem;
  }
  .InquiryWrapper .ant-input,
  .InquiryWrapper textarea.ant-input {
    font-size: 1rem !important;
  }
  .InquiryWrapper .ant-checkbox {
    transform: scale(1.2);
  }
  .InquiryBtnWrapper {
    flex-direction: row;
  }
  .InquiryBtnWrapper > button {
    font-size: 1rem;
    padding: 0.2rem;
    /* width: 8rem; */
    height: 2.5rem;
    margin: 2vw 1rem 5vw;
  }
}
